@import '../../../../../styles/consts.scss';

.fh {
  .mobile {

    &.theme-light {
      .drawer {
        .user-details {
          background-color: $fh-lowlight;
          color: $fh-white;

          .user-icon {
            color: $fh-secondary-light-gray;
          }
        }

        .drawer-menu-item {
          border-bottom: 1px solid $fh-light-gray;
        }
      }
    }

    &.theme-dark {
      .drawer {
        .user-details {
          background-color: $fh-background;
        }
      }
    }

    .drawer {
      height: 100vh;
      width: 70vw;
      border-left: solid 1px $fh-slate;

      .user-details {
        background-color: $fh-background;
        border-bottom: solid 1px $fh-slate;
        padding: 25px;

        .user-icon {
          font-size: $icon-largest-font;
        }

        .username {
          word-wrap: break-word;
        }

        .subtitle {
          line-height: 1;
          word-wrap: break-word;
        }
      }

      .drawer-menu-item {
        padding: 10px;

        .menu-item {
          font-size: $icon-regular-font;
        }  
      }
    }
  }
}