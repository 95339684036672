@import '~@fh-components/styles/src/theme-dark.scss';
@import '~@fh-components/styles/src/theme-light.scss';

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.theme-dark {

  //scroll-bar button.
  ::-webkit-scrollbar-thumb {
    background-color: $fh-riverbed;
  }

  //scroll-bar track.
  ::-webkit-scrollbar-track {
    background-color: $fh-bunker;
  }

}

.theme-light {

  //scroll-bar button.
  ::-webkit-scrollbar-thumb {
    background-color: $fh-lowlight;
  }

  //scroll-bar track.
  ::-webkit-scrollbar-track {
    background-color: $fh-secondary-light-gray;
  }

}