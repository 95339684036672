@import '../../../styles/consts.scss';

$treeItemColor: #0a0d11;

.fh {

  .theme-light {
    .select-rooms {

      .search-view {
        background: $fh-light-gray;
      }
      .rooms-list {
        ol {
          .rct-node {
            .rct-text {
              border-bottom: 1px solid $fh-light-gray;
              border-radius: 0;
              // background: $fh-light-gray;

              &:hover {
                background-color: $fh-light-gray;
              }
  
              &:active {
                background-color: lighten($fh-light-gray, 10%);
              }
            }
          }
        }
      }
    }
  }

  .theme-dark {
    .select-rooms {
      .rooms-list {
        ol {
          .rct-node {
            .rct-text {
              border-bottom: 1px solid $fh-background;
              background: $treeItemColor;

              &:hover {
                background-color: $fh-active;
              }
  
              &:active {
                background-color: lighten($fh-active, 10%);
              }
            }
          }
        }
      }
    }
  }

  .select-rooms {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .search-panel {
      padding: 16px;
      cursor: pointer;

      display: flex;
      justify-content: space-between;
      align-items: center;

      > span {
        display: flex;
        align-items: center;
      }
    }

    .search-view {
      padding: 0;

      .search-form {
        display: flex;
        width: 100%;
        box-shadow: none;

        .fh-search {
          padding: 0;
          width: 100%;

          .search-results {
            max-height: 300px;
            overflow-y: auto;
          }

          .rows-container {
            padding: 0;

            &:last-of-type:not(:first-of-type) {
              .row {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }

            .row {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 8px;
              border-radius: 0;

              .search-input {
                width: 100%;
              }

              .empty-search-results {
                padding: 0 10px;
              }

              .search-text {
                font-size: $medium-font;
              }

              .search-subText {
                font-size: $light-font;
                color: $fh-secondary-light-gray;
                display: flex;
              }
            }
          }
        }
      }
    }

    .body-container{
      margin-bottom: 20px;

      .card {
        height: 100%;
        display: flex;
        flex-direction: column;

        .rooms-list {
          flex: 1;
          min-height: 0;
          overflow: auto;
        }
      }
    }

    .rooms-list {
      padding: 10px;
      flex: 1;
      min-height: 0;
      overflow: auto;
      ol {
        .rct-node {

          &.rct-disabled {
            >.rct-text{
              >label{
                >.rct-checkbox{
                  svg {
                    color: gray;
                  }
                }
              }
            }
          }
          .rct-text {
            display: flex;
            align-items: center;
            margin: 2px 0;
            position: relative;

            // border-bottom: 1px solid $fh-background;
            // background: $treeItemColor;
            border-radius: 5px;
            z-index: 100;

            >label {
              display: flex;
              align-items: center;
              border-radius: 3px;

              &:active,
              &:hover,
              &:focus {
                background-color: transparent;
              }
            }

            >.rct-node-clickable {
              display: flex;
              align-items: center;
              background: none;
            }

            font-size: $medium-font;

            .MuiSvgIcon-root {
              width: 40px;
            }

            .rct-collapse {
              padding: 10px;
              padding-right: 5px;
            }

            .rct-collapse *,
            .rct-checkbox *,
            .rct-node-icon * {
              width: auto;
              padding: 0;
            }

            .rct-checkbox,
            .rct-node-icon,
            .rct-title {
              padding: 5px;
              align-items: center;

              .dummy-arrow {
                position: absolute;
                left: 10px;
              }
            }

            .rct-title {
              padding-right: 10px;
              min-width: 0;
              display: flex;
              align-items: center;

              .title-inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
              }
            }
          }
        }

        .rct-node-leaf {
          .rct-text {
            padding-left: 39px;

            .rct-collapse {
              display: none;
            }
          }
        }
      }
    }
  }

  .mobile {
    .content {
      .select-rooms {
        .sub-header {
          display: flex;
          justify-content: space-between;
          padding: 0 10px;

          .sub-header-title {
            display: flex;
            align-items: center;
            font-size: $regular-font;
          }

          .search-icon {
            cursor: pointer;
          }
        }
      }
    }

    .rooms-list {
      .react-checkbox-tree {
        ol {
          ol {
            padding-left: 5px;
          }

          .rct-node {
            .rct-text {  
              .rct-title {  
                .title-inner {
                  font-size: $regular-font;
                  letter-spacing: 0px;                  
                }
              }
            }
          }
        }
      }
    }

    .search-dialog {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 10px;

      .search-dialog-content {
        padding: 0;

        .fh-search {
          padding: 0;
          display: flex;
          flex-direction: column;

          .rows-container {
            padding: 0;

            &:first-of-type {
              .row {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }
            }

            &:last-of-type {
              .row {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }

            .row {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 8px 0;
              border-radius: 0;

              &.form-row {
                padding: 0;

                .search-input {
                  width: 100%;

                  .MuiInputBase-input {
                    padding: 0;
                  }
                }
              }

              .empty-search-results {
                padding: 0 10px;
              }

              .search-text {
                font-size: $medium-font;
              }

              .search-subText {
                font-size: $light-font;
                color: $fh-secondary-light-gray;
              }
            }
          }

          .search-results {
            flex: 1;
            min-height: 0;
            overflow: auto;
            border-top: 1px solid $fh-background;
          }
        }
      }
    }
  }
}