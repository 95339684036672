@import './consts.scss';
.fh {

  .picker-icon-button {
    .MuiIconButton-root {
      padding: 0px;
    }
  }

  .MuiPickersModal-dialogRoot {
    z-index: 500;

    .MuiPickersBasePicker-container {
      .MuiPickersBasePicker-pickerView {

        // date picker CSS
        .MuiPickersCalendarHeader-switchHeader {
          .MuiPickersCalendarHeader-iconButton {
            &:disabled {
              svg {
                color: $fh-slate;
              }
            }
          }
        }

        .MuiPickersDay-daySelected, .MuiPickersClockNumber-clockNumberSelected {
          color: $fh-background;
        }
        
      }
    }
  }
}