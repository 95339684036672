@import '~@fh-components/styles/src/const.scss';
@import '~@fh-components/styles/src/theme-dark.scss';
@import '~@fh-components/styles/src/theme-light.scss';
@import '~@fh-components/styles/src/theme-mixins.scss';
@import '~@fh-components/styles/src/mixins.scss';
@import '~@fh-components/styles/src/functions.scss';

/* font size for icons */
$icon-light-font : 0.875rem;
$icon-regular-font : 1rem;
$icon-medium-font : 1.5rem;
$icon-large-font : 2rem;
$icon-larger-font: 2.5rem;
$icon-largest-font: 3rem;

$page-width: 1024px;

//COLORS
$fh-light-slate: #c4c8cf;
$fh-dark-slate: #343c48;
$fh-secondary-light-gray: #acb3bc;
$fh-light-red: #ff5c3c;
$fh-dark-red: #d93616;
$fh-light-blue:#5392c7;
$fh-dark-blue:#131929;
$fh-light-gray: #ebeef2;
$fh-green-light: rgb(216, 227, 92);
$fh-amber: #FBC455;
$fh-amber-light: #DFC484;
$fh-active: rgba(46, 73, 100, 1);
$fh-background: #0B0D13;
$fh-background-light:#29313e;
$fh-popover-bg: #202634;

$fh-bg-image:  linear-gradient(to bottom, #111319 1%, #35466d 100%);
$fh-button-bg-image: linear-gradient(to bottom,#3a5184 1%,#000);

//media queries
$mobile-min-width-potrait: 320px;
$mobile-max-width-potrait: 480px;
$mobile-min-width-landscape: 481px;
$mobile-max-width-landscape: 767px;

$desktop-width: 768px;

$fontFamily: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;

:export {
  fontFamily: $fontFamily;
  ebonyclay: $fh-ebonyclay;
  background: $fh-background;
  backgroundLight: $fh-background-light;
  active: $fh-active;
  backgroundImage: $fh-bg-image;
  buttonBackgroundImage: $fh-button-bg-image;
  ebonyclayDark: $fh-ebonyclay-dark;
  riverbed: $fh-riverbed;
  bunker: $fh-bunker;
  grayChateau: $fh-gray-chateau;
  amber: $fh-amber;
  amberLight: $fh-amber-light;
  keyBlue: $fh-key-blue;
  keyBlueLight: $fh-key-blue-reversed;
  largeIconFont: $icon-large-font;
  lightBackground: $fh-white;
  lightGray: $fh-light-gray;
  secondarLightGray: $fh-secondary-light-gray;
}