@import '../../../styles/consts.scss';

.fh{

  .import-utility {
    .import-utility-card {
      .label {
        text-align: end;
        margin: auto 0;
      }
    }
  }

  .mobile {
    .import-utility {
      .import-utility-form-container {
        width: 100%;

        .import-utility-dropzone-container {
          width: 100%;
        }
      }
    }
  }
}