@import '../../../styles/consts.scss';

.fh{

  .theme-dark {
    .days {  
      &.selected {
        background-color: $fh-amber;
      }
    }

    .MuiSelect-icon{
      fill: $fh-amber;
    }
  
  }

  .theme-light {
    .days {  
      &.selected {
        background-color: $fh-key-blue;
        color: #ffffff;
      }
    }

    .MuiSelect-icon{
      fill: $fh-riverbed;
    }  
  }

  .days {
    margin: 0 1px;
    padding: 15px;
    display: inline-flex;
    cursor: pointer;
    background-color: $fh-light-gray;
  }

  .repeat-select {
    text-transform: none;
  }

  .mobile {
    .days {
      margin: 0 2px;
      width: 35px;
      height: 35px;
    }
  }

  .box {
    margin: 10px 2px;
    padding: 10px 0;
    border: 1px solid grey;
    border-radius: 10px;
  }

  .enabled {
    color: 'primary'
  }

  .disabled {
    color: $fh-secondary-light-gray;
  }
}