@import './consts.scss';
@import './card.scss';
@import './common.scss';
@import './button.scss';
@import './form.scss';
@import './autocomplete.scss';
@import './dropzone.scss';
@import './pickers.scss';
@import './scrollbar.scss';
@import './tooltip.scss';

html.fh {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: $medium-font;
  font-weight: $regular-weight;
  font-family: $fontFamily;

  body {

    margin: 0;
    height: 100%;  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    &.theme-dark {
      color: $fh-amber-light;
      background: $fh-bg-image;

      @include mobile {
        background: $fh-background;
      }  
    }

    &.theme-light {
      color: $fh-black;
      background: $fh-white;
    }


    #root {
      height: 100%;
      width: 100%;
    }
  
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }
  
    * {
      box-sizing: border-box;
    }
  
    q {
      padding: 0 5px;
    }
  
    a {
      text-decoration: underline;
      cursor: pointer;
  
      &:hover {
        color: $fh-amber;
      }
    }
  
  }
}
