@import '../../../styles/consts.scss';

.fh{

  .vacate-schedule {
    .vacate-schedule-card {
      .label {
        text-align: end;
        margin: auto 0;
      }

      .center-label {
        text-align: center;
      }
    }
  }

  .mobile {
    .vacate-schedule {
      .vacate-schedule-form-container {
        width: 100%;
        
        .places-icon {
          padding-right: 0;
        }
        .time-field {
          width: 40%;
          margin-right: 5px;
        }
      }
    }
  }
}