@import '../../../styles/consts.scss';

.fh {

  .theme-dark {
    .login-container {  
      #FhLogin-container {
        .base-form-page {
          background-image: $fh-bg-image;
          color: $fh-amber-light;
  
          .base-form {
            background-color: $fh-background;
  
            .base-form-header{
              color: $fh-amber-light;
            }
  
            .base-form-body{
              .fh-input{
                background: black;
                color: $fh-amber-light;
              }
            }  
          }
  
          button {
            background-image: $fh-button-bg-image;
            color: $fh-amber-light;
  
            &.loading {
              @include stripes($fh-light-blue);
            }
          }
  
          a {
            color: $fh-amber-light;
          }
  
          svg {
            fill: $fh-amber;
          }
  
          button[aria-label="info"] {
            background-image: none;
          }
        }  
      }
    }
  }

  .theme-light {
    .login-container {  
      #FhLogin-container {
        .base-form-page {
          background-color: $fh-light-gray;
          color: $fh-black;
  
          .base-form {
            background-color: $fh-white;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

            .base-form-header{
              color: $fh-black;
              border-bottom-color: $fh-light-gray;
              font-weight: bold;
            }
  
            .base-form-body{
              .fh-input{
                background: $fh-light-gray;
              }
            }  
          }
  
          button {
            background-image: $fh-key-blue;
            color: $fh-white;
  
            &.loading {
              @include stripes($fh-light-blue);
            }
          }
  
          a {
            color: inherit;
          }
  
          svg {
            fill: $fh-riverbed;
          }
  
          button[aria-label="info"] {
            background-image: none;
          }
        }  
      }
    }
  }

  .login-container {
    height: 100%;
    flex: 1 1 auto;

    #FhLogin-container {
      height: 100%;

      .base-form-page {
        .base-form {
          .base-form-body{
            .fh-input{
              padding: 0;
              border-bottom: none;
            }
          }

          .base-form-footer{
            .base-form-footer-buttons{
              text-align: center;
            }
          }
        }

        button {
          font-size: $medium-font;
          width: 75%;
        }

        button[aria-label="info"] {
          width: 100%;
        }
      }
    }
  }

  .version-footer {
    position: fixed;
    right: 10px;
    bottom: 10px;
    opacity: 0.5;
    font-size: $light-font;
  }

  .mobile {
    
    &.theme-light{
      .login-container {
        #FhLogin-container {
          .base-form-page {
            background-color: $fh-white;

            .base-form {
              color: inherit;
              background-color: inherit;
              .fh-textfield {  
                label {
                  &.Mui-focused {
                    // color: $fh-amber-light;
                  }
                }
  
                .MuiFilledInput-underline {
                  background-color: $fh-light-gray;
                  // color: $fh-black;
                }
              }  

            }
          }
        }
      }
    }

    &.theme-dark{
      .login-container {
        #FhLogin-container {
          .base-form-page {
            .base-form {
              .fh-textfield {  
                label {
                  &.Mui-focused {
                    color: $fh-amber-light;
                  }
                }
  
                .MuiFilledInput-underline {
                  background-color: black;
                  color: $fh-amber-light;
                }
              }  
            }
          }
        }
      }
    }


    .login-container {
      #FhLogin-container {
        .base-form-page {
          .base-form {
            .fh-textfield {
              display: block;
              .MuiFilledInput-underline {
                border-bottom: none;
                &:hover {
                  border-bottom: none;
                }
              }
            }

            button {
              width: 100%;
            }
          }
        }
      }
    }

    .version-footer {
      display: none;
    }
  }
}