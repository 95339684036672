@import './consts.scss';
.fh {
  .MuiAutocomplete-root {

    .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
      padding: 5px;

      .MuiAutocomplete-clearIndicator {
        svg {
          font-size: $icon-regular-font;
        }
      }
      
      .MuiAutocomplete-endAdornment {
        button {
          &[disabled] {
            background-color: transparent;

            svg {
              color: $fh-dark-slate;
            }
          }
        }
      }
    }
  }

  .mobile {
    .MuiAutocomplete-inputRoot {
      padding: 19px 65px 5px 8px;
    }
  }
}