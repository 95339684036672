@import '../../../styles/consts.scss';

.fh{
  .branding {
    display: flex;
    justify-content: center;
    background-color: $fh-light-gray;
    padding: 5px;
    box-shadow: 5px 0 0 0 rgba(0,0,0,0.35);
    
    .logo {
      height: 50px;
      width: 200px;
      padding: 0px 20px;
      border-right: solid 2px #dfe3e8;

      &:last-of-type {
        border-right: none;  
      }
    }
  }

  .mobile {

    .branding {
      display: flex;
      flex-direction: column;
      padding: 0;

      .brand-logo-container {
        display: flex;
        justify-content: center;
        background-color: $fh-light-gray;
        padding: 2px;
    
        .logo {
          height: 30px;
          width: 200px;
          padding: 0px 20px;
          border-right: solid 2px #dfe3e8;
    
          &:last-of-type {
            border-right: none;  
          }
        }  
      }

      .foghorn-logo-container {
        background-color: $fh-dark-slate;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;

        .logo {
          height: 80%;
        }
      }
    }
  }
}