@import '../../../styles/consts.scss';

.fh{

  .schedule-details {
    .schedule-details-card {
      .label {
        text-align: end;
        margin: auto 0;
      }

      .center-label {
        text-align: center;
      }
    }
  }

  .mobile {
    .schedule-details {
      .sub-header {
        text-align: center;
        padding: 0 10px;
        position: relative;
        padding: 12px;
      }

      .form-container{
        width: 100%;

        .request-details-title {
          font-size: $regular-font;
          font-weight: $heavy-weight;
        }

        .info-item {
          overflow-wrap: break-word;
        }

        .time-field {
          margin-right: 5px;
          width: 40%;
        }
      }
    }
  }
}