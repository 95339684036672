@import '../../../styles/consts.scss';
.fh {
  .MuiSnackbar-root {
    &.error {
      color: $fh-light-red;
    }

    &.success {
      color: $fh-green-light;
    }

    .MuiSnackbarContent-root {
      color: inherit;

      .MuiSnackbarContent-message {
        width: 85%;
      }
    }
  }
}