@import '../../../styles/consts.scss';

.fh {

  .header-menu-popper {
    z-index: 101;
  }

  .theme-light {
    .header {
      background: $fh-lowlight;

      .header-text {
        color: $fh-light-slate;
      }

      .header-menu-openner{
        color: $fh-white;
        svg {
          color: $fh-white;
        }
      }

      // .header-menu-container {
      //   background-color: white;
      //   color: black;
      // }
    }
  }

  .theme-dark {
    .header {
      background: $fh-dark-blue;
    }
  }

  .header {
    padding: 0 10px;

    .MuiToolbar-gutters{
      padding: 0 10px;
    }

    .header-text {
      font-weight: $heavy-weight;
    }

    .header-grow {
      flex-grow: 1;
    }

    .header-menu-openner{
      svg {
        margin-right: 5px;
      }
    }
  }

  .mobile {
    .header{
      padding: 0;

      .title{
        width: 100%;
        text-align: center;
        font-size: $large-font;
      }

      .MuiAvatar-root{
        height: 30px;
        width: 30px;
      }
    }
  }
}