@import'./consts.scss';

.fh{

  button {
    text-transform: none;
    &[disabled] {
      // background: $fh-dark-slate;
      pointer-events: none;
    }  
  }

  .mobile{
    button {
      border-radius: 25px;
    }
  }
}