@import'./consts.scss';

.fh{

  .theme-light {
    .MuiFormControl-root {
      .MuiInputLabel-root {
        &.Mui-focused {
          color: inherit;
        }
      }
    }

    .MuiCheckbox-colorSecondary {
      &.Mui-checked {
        color: $fh-riverbed;
      }
    }
  }

  form {
    display: contents;
  }

  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 12px;
      height: inherit;
    }
  }

  .MuiFilledInput-multiline {
    padding: 0;
  }

  .MuiFilledInput-underline {
    &::before {
      border-bottom: none;
    }
    &:hover {
      &::before {
        border-bottom: none;
      }
    }
    &::after {
      border-bottom: none;
    }
    &.Mui-disabled:before {
      border-bottom-style: none;
    }
  }
  
  .MuiFormControl-root {
    .select-dropdown-label {
      padding-left: 10px;
      z-index: 100;
      pointer-events: none;
    }

    .MuiInputLabel-shrink {
      &.select-dropdown-label {
        padding-left: 15px;
        padding-top: 10px;
      }
    }
  }

  input[type=date]::-webkit-calendar-picker-indicator {
    background: url('../images/icon-calendar.svg') no-repeat;
    width: 24px;
    height: 24px;
    transform:scale(0.6);
    -webkit-transform:scale(0.6);
  }

  input[type=time]::-webkit-calendar-picker-indicator {
    background: url('../images/icon-clock.svg') no-repeat;
    width: 24px;
    height: 24px;
    transform:scale(0.6);
    -webkit-transform:scale(0.6);
  }

  .mobile{
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $fh-slate;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $fh-slate;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $fh-slate;
    }

    input[value=""] {
      &::-webkit-datetime-edit {
        color: transparent;
      }
    }

    .MuiInputBase-input {
      padding: 27px 10px 10px;
    }

    .MuiListItemText-primary {
      font-size: $icon-light-font;
      font-weight: $regular-weight;
      line-height: 1.43;
      letter-spacing: 0.01071em;
    }

    .MuiListItemText-secondary {
      font-size: $icon-regular-font;
      font-weight: $regular-weight;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }

    .MuiListItem-root{
      padding-right: 10px;
      padding-left: 10px;

      .MuiListItemAvatar-root {
        min-width: 48px;
        padding: 0 10px;
      }
    }

    .MuiFormControl-root {
      width: 100%;
    }
  }
}