@import './consts.scss';

.fh {
  .MuiDropzoneArea-root {
    min-height: 150px;

    .MuiDropzoneArea-textContainer {
      .MuiDropzoneArea-text {
        font-size: $large-font;
      }
    }
  }

  .MuiDropzonePreviewList-root {
    .MuiDropzonePreviewList-image {
      height: 50px;
    }
  }
}