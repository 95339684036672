@import '../../../styles/consts.scss';

.fh {
  .approval-container {
    overflow-wrap:break-word;

    .booking-data-message {
      padding: 10px;
    }

    .booking-data {
      width: 100%;
      padding: 0 5px;

      .approval-card {
        .label {
          text-align: end;
        }

        .checkbox {
          padding: 0%;
        }

        .checkbox-label {
          align-self: center;
        }
      }
    }

    .room-list {
      padding: 0;
      max-height: 250px;
      overflow-y: auto;
      width: 100%;
            
      .hierarchy {
        font-size: inherit;
      }

      .room-name {
        font-size: inherit;
      }
    }
  }
}