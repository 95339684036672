@import '../styles/consts.scss';

.fh{

  .theme-light {
    .container{
      .content {
        .title-container {
          .title {
            color: $fh-black;
          }
        }
      }
    }
  }

  .theme-dark {
    .container{
      .content {
        .title-container {
          .title {
            color: $fh-amber-light;
          }
          .sub-title {
            color: $fh-secondary-light-gray;
          }
        }
      }
    }
  }

  .container{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: auto;

    .header{
      box-shadow: 0 04px 10px rgba(black, 0.3);
    }

    .content {
      flex: 1;
      min-height: 0;

      .MuiContainer-root{
        height: 100%;
      }

      .title-container {
        padding: 20px 0;

        .title {
          display: block;
          font-size: $medium-font;

          &.error {
            color: $fh-dark-red;
          }
        }
      }

      .body-container{
        flex: 1;
        min-height: 0;
      }
    }

    .grow {
      flex-grow: 1;
    }

    .footer {
      padding: 10px 0;
      flex: 0 1 100px;
    }

    .branding {
      flex: 0 0 auto;
    }
  }

  .mobile {

    &.theme-light {
      .container{
        .header {
          color: $fh-white;
        }

        .content {
          .sub-header {
            border-bottom: solid 1px $fh-light-gray;
          }
        }
      }
    }
  
    .theme-dark {
      .content {
        .sub-header {
          border-bottom: solid 1px rgba(white, 0.1);
        }
      }
    }
  
    .content {
      overflow-y: auto;
      .sub-header {
        position: relative;
        padding: 12px;
        text-align: center;

        .back-icon {
          position: absolute;
          left: 10px;
          top: 0;
        }

        .right-icon {
          position: absolute;
          right: 10px;
          top: 0;
        }
      }
    }

    .footer {
      flex: 0 1 40px;
      box-shadow: 0 -2px 8px -2px rgba(0,0,0,.33);

      .submit-button-container {
        width: 100%;
        text-align: center;

        .submit-button {
          margin: 5px auto;
          padding: 10px 40px;
          width: 80%;
        }
      }
    }
  }
}