@import '../../../styles/consts.scss';

.fh {

  .theme-dark {
    .contact-us {
      a {
        color: $fh-amber-light;
      }  
    }
  
  }

  .contact-us {
    height: 100%;

    .content {
      height: 100%;

      .MuiGrid-root.MuiGrid-container {
        height: 100%;
      }
    }

    img {
      width: 200px;
    }

    h2 {
      font-weight: normal;
    }

    p {
      margin-top: 30px;
    }

    ul {
      text-align: left;
    }

    &.mobile {
      text-align: center;

      .content {
        margin: auto;
        max-width: 320px;
      }

      .footer {
        text-align: left;
      }
    }
  }
}