
.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mr {
  margin-right: 8px;
}

.va-top {
  vertical-align: top;
}

.text-center {
  text-align: center;
}

.p-0 {
  padding: 0 !important;
}
