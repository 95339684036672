@import '../../../styles/consts.scss';

.fh {
  .fh-dialog {
    .MuiDialog-paper {
      z-index: 500;

      .MuiDialogTitle-root {
        font-size: $medium-font;
      }

      .fh-dialog-content {
        font-size: $regular-font;
      }
    }
  }
}