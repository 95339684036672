@import './consts.scss';

.fh {

  .theme-light {
    .MuiCard-root.MuiPaper-root {
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);

      .card-header {
        border-bottom: 1px solid $fh-secondary-light-gray;
        background-color: $fh-light-gray;
      }
    }
  }

  .theme-dark {
    .MuiCard-root.MuiPaper-root {
      box-shadow: 0 5px 10px rgba(black, 0.3);

      .card-header {
        border-bottom: 1px solid $fh-background-light;
        background-color: $fh-background-light;
      }
    }
  }

  .MuiCard-root.MuiPaper-root {
    border-radius: 5px;
    margin-bottom: 10px;
  
    .card-header {
      padding: 0 !important;
      font-size: $medium-font;
    }
  
    .card-body {
      padding: 15px;
  
      &::before {
        display: table;
        content: " ";
        box-sizing: border-box;
      }
  
      &::after {
        clear: both;
      }
    }
  
    .MuiCardContent-root {
      padding: 24px;
    }
  }
}
