@import './consts.scss';

.fh {
  .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      background: $fh-popover-bg;
      font-size: $regular-font;
      border: 1px solid lighten($fh-popover-bg, 10%);
    }

    .MuiTooltip-arrow {
      color: $fh-popover-bg;

      &:before {
        border: 1px solid lighten($fh-popover-bg, 10%);
        height: 9px;
        width: 11px;
        left: 2px;
        position: relative;
      }
    }
  }
}